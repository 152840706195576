<template>
    <form class="subscription-form accent-secondary-dark"
          method="post"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
    >
        <!-- Anchors -->
        <div id="inscription-mini"></div>
        <div id="inscription-maxi"></div>

        <csrf-input />
        <input type="hidden" name="action" :value="action"/>
        <input type="hidden" name="enabled" value="true" />
        <input type="hidden" name="sectionId" :value="sectionId" />

        <div class="grid-container py-10 md:py-20">
            <div class="form">
                <h2 class="w-full text-center mb-6 md:mb-20">{{ heading }}</h2>

                <p class="w-full mb-10 text-xl text-center text-red-500">
                    Nous ne pouvons malheureusement pas garantir un repas pour les inscrits après le mardi 26 septembre.
                </p>

                <div class="flex flex-wrap">
                    <fieldset class="form__fieldset w-full">
                        <legend class="form__fieldset-legend">Équipe</legend>

                        <div class="flex flex-wrap">
                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label class="form__label" for="email">
                                    Adresse e-mail
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="email"
                                       v-model="formData.email"
                                       id="email"
                                       name="fields[subscriptionsEmail]"
                                       required
                                       autocomplete="email"
                                />
                                <div v-if="errors.subscriptionsEmail"
                                     v-text="errors.subscriptionsEmail"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label class="form__label" for="isTandem">Je m'inscris en biplace</label>
                                <light-switch v-model:checked="formData.isTandem"
                                              class="flex items-center py-1"
                                              id="isTandem"
                                              name="fields[subscriptionsIsTandem]"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Parcours
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="raceMini"
                                               v-model="formData.race"
                                               value="mini"
                                               class="mr-2"
                                               name="fields[subscriptionsRace]"
                                               required
                                        />
                                        <label for="raceMini">MiNi FIZ</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="raceMaxi"
                                               v-model="formData.race"
                                               value="maxi"
                                               class="mr-2"
                                               name="fields[subscriptionsRace]"
                                               required
                                        />
                                        <label for="raceMaxi">MaXi FIZ</label>
                                    </li>
                                </ul>
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Photo
                                </div>

                                <file-upload name="fields[subscriptionsPicture][]"
                                             v-model="formData.picture"
                                             accept="image/jpeg,image/png"

                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 1 Mo, format JPG ou PNG</p>

                                <div v-if="errors.subscriptionsPicture"
                                     v-text="errors.subscriptionsPicture"
                                     class="form__field-error"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="form__fieldset w-full">
                        <legend class="form__fieldset-legend">Pilote</legend>

                        <div class="flex flex-wrap">
                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="pilotLastName" class="form__label">
                                    Nom
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="text"
                                       v-model="formData.pilotLastName"
                                       id="pilotLastName"
                                       name="fields[subscriptionsPilotLastName]"
                                       required
                                       autocomplete="family-name"
                                />
                                <div v-if="errors.subscriptionsPilotLastName"
                                     v-text="errors.subscriptionsPilotLastName"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="pilotFirstName" class="form__label">
                                    Prénom
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="text"
                                       v-model="formData.pilotFirstName"
                                       id="pilotFirstName"
                                       name="fields[subscriptionsPilotFirstName]"
                                       required
                                       autocomplete="given-name"
                                />
                                <div v-if="errors.subscriptionsPilotFirstName"
                                     v-text="errors.subscriptionsPilotFirstName"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="pilotDateOfBirth" class="form__label">
                                    Date de naissance
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="date"
                                       v-model="formData.pilotDateOfBirth"
                                       id="pilotDateOfBirth"
                                       name="fields[subscriptionsPilotDateOfBirth]"
                                       required
                                       autocomplete="bday"
                                />
                                <div v-if="errors.subscriptionsPilotDateOfBirth"
                                     v-text="errors.subscriptionsPilotDateOfBirth"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="pilotPhoneNumber" class="form__label">
                                    Numéro de téléphone
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="tel"
                                       v-model="formData.pilotPhoneNumber"
                                       id="pilotPhoneNumber"
                                       name="fields[subscriptionsPilotPhoneNumber]"
                                       required
                                       autocomplete="tel"
                                />
                                <div v-if="errors.subscriptionsPilotPhoneNumber"
                                     v-text="errors.subscriptionsPilotPhoneNumber"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Sexe
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotGenderFemale"
                                               value="F"
                                               v-model="formData.pilotGender"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotGender]"
                                               required
                                        />
                                        <label for="pilotGenderFemale">Femme</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotGenderMale"
                                               value="M"
                                               v-model="formData.pilotGender"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotGender]"
                                               required
                                        />
                                        <label for="pilotGenderMale">Homme</label>
                                    </li>
                                </ul>
                                <div v-if="errors.subscriptionsPilotGender"
                                     v-text="errors.subscriptionsPilotGender"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="pilotIsVegetarian" class="form__label">Je suis végétarien</label>
                                <light-switch v-model:checked="formData.pilotIsVegetarian"
                                              class="flex items-center mb-0 py-1"
                                              id="pilotIsVegetarian"
                                              name="fields[subscriptionsPilotIsVegetarian]"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Taille de T-shirt
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotTshirtSizeXs"
                                               value="xs"
                                               v-model="formData.pilotTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotTshirtSize]"
                                               required
                                        />
                                        <label for="pilotTshirtSizeXs">XS</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotTshirtSizeS"
                                               value="s"
                                               v-model="formData.pilotTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotTshirtSize]"
                                               required
                                        />
                                        <label for="pilotTshirtSizeS">S</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotTshirtSizeM"
                                               value="m"
                                               v-model="formData.pilotTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotTshirtSize]"
                                               required
                                        />
                                        <label for="pilotTshirtSizeM">M</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotTshirtSizeL"
                                               value="l"
                                               v-model="formData.pilotTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotTshirtSize]"
                                               required
                                        />
                                        <label for="pilotTshirtSizeL">L</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotTshirtSizeXl"
                                               value="xl"
                                               v-model="formData.pilotTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotTshirtSize]"
                                               required
                                        />
                                        <label for="pilotTshirtSizeXl">XL</label>
                                    </li>
                                </ul>
                                <div v-if="errors.subscriptionsPilotTshirtSize"
                                     v-text="errors.subscriptionsPilotTshirtSize"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Certificat médical
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsPilotMedicalCertificate][]"
                                             v-model="formData.pilotMedicalCertificate"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                 <div v-if="errors.subscriptionsPilotMedicalCertificate"
                                     v-text="errors.subscriptionsPilotMedicalCertificate"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label class="form__label" for="pilotHasFfvlLicense">Je suis licencié FFVL</label>
                                <light-switch v-model:checked="formData.pilotHasFfvlLicense"
                                              class="flex items-center py-1"
                                              id="pilotHasFfvlLicense"
                                              name="fields[subscriptionsPilotHasFfvlLicense]"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="formData.pilotHasFfvlLicense">
                                <div class="form__label">
                                    Licence FFVL
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsPilotFfvlLicense][]"
                                             v-model="formData.pilotFfvlLicense"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                 <div v-if="errors.subscriptionsPilotFfvlLicense"
                                     v-text="errors.subscriptionsPilotFfvlLicense"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="formData.isTandem">
                                <div class="form__label">
                                    <abbr title="Qualification biplace">QBI</abbr>
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsQbi][]"
                                             v-model="formData.qbi"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                 <div v-if="errors.subscriptionsQbi"
                                     v-text="errors.subscriptionsQbi"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="formData.isTandem">
                                <div class="form__label">
                                    Assurance biplace
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsTandemInsurance][]"
                                             v-model="formData.tandemInsurance"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                <div v-if="errors.subscriptionsTandemInsurance"
                                     v-text="errors.subscriptionsTandemInsurance"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    J'ai un casque homologué
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="pilotHasCertifiedHelmetYes"
                                               value="1"
                                               v-model="formData.pilotHasCertifiedHelmet"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotHasCertifiedHelmet]"
                                               required
                                        />
                                        <label for="pilotHasCertifiedHelmetYes">Oui</label>
                                    </li>
                                    <li>
                                        <input type="radio"
                                               id="pilotHasCertifiedHelmetNo"
                                               value="0"
                                               v-model="formData.pilotHasCertifiedHelmet"
                                               class="mr-2"
                                               name="fields[subscriptionsPilotHasCertifiedHelmet]"
                                               required
                                        />
                                        <label for="pilotHasCertifiedHelmetNo">Non</label>
                                    </li>
                                </ul>
                                <p class="text-xs mt-1 text-slate-600">Un casque homologué (norme EN 966) est obligatoire en compétition.</p>
                                <p v-if="formData.pilotHasCertifiedHelmet === '0'" class="text-xs form__field-error">
                                    Il faudra vous en faire prêter un pour le jour de la compétition, sans quoi vous ne pourrez pas participer.
                                </p>
                                <div v-if="errors.subscriptionsPilotHasCertifiedHelmet"
                                     v-text="errors.subscriptionsPilotHasCertifiedHelmet"
                                     class="form__field-error"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset v-if="formData.isTandem" class="form__fieldset w-full">
                        <legend class="form__fieldset-legend">Passager</legend>

                        <div class="flex flex-wrap">
                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="passengerLastName" class="form__label">
                                    Nom
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="text"
                                       v-model="formData.passengerLastName"
                                       id="passengerLastName"
                                       name="fields[subscriptionsPassengerLastName]"
                                       required
                                />
                                <div v-if="errors.subscriptionsPassengerLastName"
                                     v-text="errors.subscriptionsPassengerLastName"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="passengerFirstName" class="form__label">
                                    Prénom
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="text"
                                       v-model="formData.passengerFirstName"
                                       id="passengerFirstName"
                                       name="fields[subscriptionsPassengerFirstName]"
                                       required
                                />
                                <div v-if="errors.subscriptionsPassengerFirstName"
                                     v-text="errors.subscriptionsPassengerFirstName"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="passengerDateOfBirth" class="form__label">
                                    Date de naissance
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="date"
                                       v-model="formData.passengerDateOfBirth"
                                       id="passengerDateOfBirth"
                                       name="fields[subscriptionsPassengerDateOfBirth]"
                                       required
                                />
                                <div v-if="errors.subscriptionsPassengerDateOfBirth"
                                     v-text="errors.subscriptionsPassengerDateOfBirth"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="passengerPhoneNumber" class="form__label">
                                    Numéro de téléphone
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </label>
                                <input type="tel"
                                       v-model="formData.passengerPhoneNumber"
                                       id="passengerPhoneNumber"
                                       name="fields[subscriptionsPassengerPhoneNumber]"
                                       required
                                       autocomplete="tel"
                                />
                                <div v-if="errors.subscriptionsPassengerPhoneNumber"
                                     v-text="errors.subscriptionsPassengerPhoneNumber"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Sexe
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerGenderFemale"
                                               value="F"
                                               v-model="formData.passengerGender"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerGender]"
                                               required
                                        />
                                        <label for="passengerGenderFemale">Femme</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerGenderMale"
                                               value="M"
                                               v-model="formData.passengerGender"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerGender]"
                                               required
                                        />
                                        <label for="passengerGenderMale">Homme</label>
                                    </li>
                                </ul>
                                <div v-if="errors.subscriptionsPassengerGender"
                                     v-text="errors.subscriptionsPassengerGender"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <label for="passengerIsVegetarian" class="form__label">Mon passager est végétarien</label>
                                <light-switch v-model:checked="formData.passengerIsVegetarian"
                                              class="flex items-center mb-0 py-1"
                                              id="passengerIsVegetarian"
                                              name="fields[subscriptionsPassengerIsVegetarian]"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Taille de T-shirt
                                    <span aria-hidden="true" title="Ce champ est requis">*</span>
                                </div>
                                <ul class="flex">
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerTshirtSizeXs"
                                               value="xs"
                                               v-model="formData.passengerTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerTshirtSize]"
                                               required
                                        />
                                        <label for="passengerTshirtSizeXs">XS</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerTshirtSizeS"
                                               value="s"
                                               v-model="formData.passengerTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerTshirtSize]"
                                               required
                                        />
                                        <label for="passengerTshirtSizeS">S</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerTshirtSizeM"
                                               value="m"
                                               v-model="formData.passengerTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerTshirtSize]"
                                               required
                                        />
                                        <label for="passengerTshirtSizeM">M</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerTshirtSizeL"
                                               value="l"
                                               v-model="formData.passengerTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerTshirtSize]"
                                               required
                                        />
                                        <label for="passengerTshirtSizeL">L</label>
                                    </li>
                                    <li class="mr-5">
                                        <input type="radio"
                                               id="passengerTshirtSizeXl"
                                               value="xl"
                                               v-model="formData.passengerTshirtSize"
                                               class="mr-2"
                                               name="fields[subscriptionsPassengerTshirtSize]"
                                               required
                                        />
                                        <label for="passengerTshirtSizeXl">XL</label>
                                    </li>
                                </ul>
                                <div v-if="errors.subscriptionsPassengerTshirtSize"
                                     v-text="errors.subscriptionsPassengerTshirtSize"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3">
                                <div class="form__label">
                                    Certificat médical
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsPassengerMedicalCertificate][]"
                                             v-model="formData.passengerMedicalCertificate"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                <div v-if="errors.subscriptionsPassengerMedicalCertificate"
                                     v-text="errors.subscriptionsPassengerMedicalCertificate"
                                     class="form__field-error"
                                />
                            </div>

                            <div class="form__field w-full sm:w-1/2 md:w-1/3" v-if="isPassengerMinor">
                                <div class="form__label">
                                    Autorisation parentale
                                    <span aria-hidden="true" title="Ce champ est requis">**</span>
                                </div>

                                <file-upload name="fields[subscriptionsPassengerParentalAuthorization][]"
                                             v-model="formData.passengerParentalAuthorization"
                                />
                                <p class="text-xs mt-1 text-slate-600">Maximum 2 Mo, format JPG, PNG ou PDF</p>

                                <a :href="parentalAuthorizationTemplateUrl" class="text-xs mt-2" target="_blank" download="autorisation-parentale-fly-in-fiz.pdf">
                                    Modèle d'autorisation parentale
                                </a>

                                <div v-if="errors.subscriptionsPassengerParentalAuthorization"
                                     v-text="errors.subscriptionsPassengerParentalAuthorization"
                                     class="form__field-error"
                                />
                            </div>
                        </div>

                        <div class="form__field w-full sm:w-1/2 md:w-1/3">
                            <div class="form__label">
                                Mon passager a un casque homologué
                                <span aria-hidden="true" title="Ce champ est requis">*</span>
                            </div>
                            <ul class="flex">
                                <li class="mr-5">
                                    <input type="radio"
                                           id="passengerHasCertifiedHelmetYes"
                                           value="1"
                                           v-model="formData.passengerHasCertifiedHelmet"
                                           class="mr-2"
                                           name="fields[subscriptionsPassengerHasCertifiedHelmet]"
                                           required
                                    />
                                    <label for="passengerHasCertifiedHelmetYes">Oui</label>
                                </li>
                                <li>
                                    <input type="radio"
                                           id="passengerHasCertifiedHelmetNo"
                                           value="0"
                                           v-model="formData.passengerHasCertifiedHelmet"
                                           class="mr-2"
                                           name="fields[subscriptionsPassengerHasCertifiedHelmet]"
                                           required
                                    />
                                    <label for="passengerHasCertifiedHelmetNo">Non</label>
                                </li>
                            </ul>
                            <p class="text-xs mt-1 text-slate-600">Un casque homologué (norme EN 966) est obligatoire en compétition.</p>
                            <p v-if="formData.passengerHasCertifiedHelmet === '0'" class="text-xs form__field-error">
                                Il faudra vous en faire prêter un pour le jour de la compétition, sans quoi vous ne pourrez pas participer.
                            </p>
                            <div v-if="errors.subscriptionsPassengerHasCertifiedHelmet"
                                 v-text="errors.subscriptionsPassengerHasCertifiedHelmet"
                                 class="form__field-error"
                            />
                        </div>
                    </fieldset>

                    <p class="grid-col w-full text-xs text-slate-600 mt-4">Les champs marqués d'un astérisque (*) sont requis.</p>
                    <p class="grid-col w-full text-xs text-slate-600 mt-4">Les champs marqués d'un double astérisque (**) indiquent des fichiers facultatifs pour l'inscription mais qui devront être transmis avant le départ de la course.</p>
                    <div class="w-full flex justify-center mt-10 md:mt-20">
                        <button type="submit" class="btn btn--white bg-secondary-dark">Je m'inscris</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script setup>
import LightSwitch from "@/app/components/LightSwitch.vue";
import CsrfInput from "@/app/components/CsrfInput.vue";
import FileUpload from "@/app/components/FileUpload.vue";

import {onBeforeUnmount, onMounted, reactive, computed} from "vue";

const props = defineProps({
    action: { type: String, required: true },
    heading: { type: String },
    initialFormData: { type: Object, default: () => ({})},
    errors: { type: Object, required: false, default: () => ({}) },
    sectionId: { type: String, required: true },
    parentalAuthorizationTemplateUrl: { type: String, required: true },
});

const formData = reactive({
    email: props.initialFormData.email,
    isTandem: props.initialFormData.isTandem ?? false,
    race: props.initialFormData.race ?? 'mini',
    picture: undefined,
    pilotLastName: props.initialFormData.pilotLastName,
    pilotFirstName: props.initialFormData.pilotFirstName,
    pilotDateOfBirth: props.initialFormData.pilotDateOfBirth,
    pilotPhoneNumber: props.initialFormData.pilotPhoneNumber,
    pilotGender: props.initialFormData.pilotGender,
    pilotIsVegetarian: props.initialFormData.pilotIsVegetarian ?? false,
    pilotTshirtSize: props.initialFormData.pilotTshirtSize,
    pilotMedicalCertificate: undefined,
    pilotHasFfvlLicense: props.initialFormData.pilotHasFfvlLicense,
    pilotFfvlLicense: undefined,
    qbi: undefined,
    tandemInsurance: undefined,
    pilotHasCertifiedHelmet: props.initialFormData.pilotHasCertifiedHelmet,

    passengerLastName: props.initialFormData.passengerLastName,
    passengerFirstName: props.initialFormData.passengerFirstName,
    passengerDateOfBirth: props.initialFormData.passengerDateOfBirth,
    passengerPhoneNumber: props.initialFormData.passengerPhoneNumber,
    passengerGender: props.initialFormData.passengerGender,
    passengerIsVegetarian: props.initialFormData.passengerIsVegetarian ?? false,
    passengerTshirtSize: props.initialFormData.passengerTshirtSize,
    passengerMedicalCertificate: undefined,
    passengerParentalAuthorization: undefined,
    passengerHasCertifiedHelmet: props.initialFormData.passengerHasCertifiedHelmet,
});

const isPassengerMinor = computed(() => {
    if (!formData.isTandem || !formData.passengerDateOfBirth) {
        return false;
    }

    const majorityDate = new Date(formData.passengerDateOfBirth);
    majorityDate.setFullYear(majorityDate.getFullYear() + 18);

    const contestDate = new Date('2023-09-30');

    return majorityDate > contestDate;
});

// Change the value of the race field upon URL hash change
onMounted(() => {
    window.addEventListener('hashchange', onHashChanged);
});

onBeforeUnmount(() => {
    window.removeEventListener('hashchange', onHashChanged);
});

function onHashChanged() {
    switch(location.hash) {
        case '#inscription-mini':
            formData.race = 'mini';
            break;
        case '#inscription-maxi':
            formData.race = 'maxi';
            break;
    }
}
</script>

<style scoped>
.subscription-form {
    @apply bg-secondary-light;
    background-image: url("~@/assets/topo-lines.png");
    background-size: 400px;
}
</style>
